<template>
    <!----Top Artist Section---->
            <div class="ms_top_artist">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="ms_heading">
                                <h1>{{ this.$route.params.cat_name }}</h1>
                            </div>
                        </div>
                        <div class="col-6 col-sm-4 col-lg-3 col-xl-2" v-for="(art, index) in artist" :key="index">
                            <div class="ms_rcnt_box marger_bottom30" @click="getFeed(art)">
                                <div class="ms_rcnt_box_img">
                                    <img v-lazy="art.img" alt="" class="img-fluid">
                                    <div class="ms_main_overlay">
                                        <div class="ms_box_overlay"></div>
                                        <div class="ms_more_icon">
                                            <img src="../../assets/images/svg/more.svg" alt="">
                                        </div>
                                        <ul class="more_option">
                                            <li><a href="#"><span class="opt_icon"><span class="icon icon_fav"></span></span>Add To Favourites</a></li>
                                            <li><a href="#"><span class="opt_icon"><span class="icon icon_queue"></span></span>Add To Queue</a></li>
                                            <li><a href="#"><span class="opt_icon"><span class="icon icon_playlst"></span></span>Add To Playlist</a></li>
                                        </ul>
                                        <div class="ms_play_icon">
                                            <img src="../../assets/images/svg/play.svg" alt="">
                                        </div>
                                    </div>
                                </div>
                                <div class="ms_rcnt_box_text pointer">
                                    <h3><a @click="getFeed(art)">{{ art.name }}</a></h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
</template>

<script>
import { HTTP } from '@/Api/http-common';
import { ref } from '@vue/reactivity';
import { useRouter, useRoute } from "vue-router"
import { useCookie } from 'vue-cookie-next';
export default {
    name: 'AppCategories',
    props: {
      cat_id: String,
    },

    async setup(props) {
      const router = useRouter();
      const route = useRoute();
      const cookie = useCookie();
      const artist = ref([]); 

      try {
        await HTTP.get(`GetArtistsByCategory.php?ID=${props.cat_id}`).then((res) => {
            artist.value = res.data.Artists; 
        });
      } catch (err) {
        console.log(err);
      }

      const getFeed = (art) => {
        // cookie.setCookie('cat_id', publish.cat_id);
        // if(cookie.isCookieAvailable("msisdn") && cookie.isCookieAvailable("status")) {
          router.push({ name: "Contents", params: { content_id: art.id, cat_name: art.cat_name } });
        // } else {
        //   cookie.setCookie('content_id', publish.id);
        //   router.push({ name: "Subscribes" });
        // }
      };
      return { artist, getFeed };
   },
}
</script>

<style>
.pointer {
  cursor: pointer;
}
</style>